<template>
	<div class="yh">
		<div class="ysm-head">
				<img src="../../assets/ysmimg/ysmlogo.jpg" width="300" style="float: left;" />
				<div style="float: right;padding-right: 1.25rem;">
					<img src="../../assets/ysmimg/phone.png" width="30px" style="vertical-align: middle;" >&nbsp;<span style="margin-right: 1.25rem;font-weight: normal;">0531-88762550</span>
					<img src="../../assets/ysmimg/home.png" width="20px" style="vertical-align: middle;padding-bottom: 0.135rem;" >&nbsp;<a style="margin-right: 1.25rem;color:#000000;" href="http://www.techlion.com.cn/">官网首页</a>
					<!-- <span  style="margin-right: 0.625rem;display: inline-block;font-weight: bolder;cursor: pointer;">{{dname}}</span> -->
					<span  style="margin-right: 0.625rem;display: inline-block;font-weight: bolder;cursor: pointer;" @click="goSettings" >{{dname}}</span>
					<button class="logout" @click="logout">登出</button>
				</div>
		</div>
		<div class="nav1" >
				<a style="padding-left: 2.125rem;"@click="goMain">首页</a>
				<a @click="goOrder">订单</a>
				<a @click="settings">设置</a>
		</div>
		<div class="fun sub-menue">
			<div @click="back" style="display: flex;flex-direction: column;align-items: center;border-right: 1px solid #af9f73;cursor: pointer;padding-right: 0.625rem;" >
				<img src="../../assets/ysmimg/arrow-left.png" width="70%" />
				<span style="color:#af9f73 ;">返回</span>
			</div>
			<!-- <div @click="goAddOrder" style="display: flex;flex-direction: column;align-items: center;margin-left: 0.925rem;cursor: pointer;" >
				<img id="addfile" :src="src" width="60%" @mouseenter="change" @mouseleave="leavechange" />
				<span class="tjdd" style="color:#FFFFFF;font-size: 0.9375rem;">添加订单</span>
			</div> -->
			
			
		</div>
		<div class="fun1 sub-menue1">
			<div @click="back" style="display: flex;flex-direction: column;align-items: center;border-right: 1px solid #af9f73;cursor: pointer;padding-right: 0.625rem;" >
				<img src="../../assets/ysmimg/arrow-left.png" width="70%" />
				<span style="color:#af9f73 ;">返回</span>
			</div>
			<div @click="SaveOrder" style="display: flex;flex-direction: column;align-items: center;margin-left: 0.925rem;cursor: pointer;" >
				<img id="addfile" :src="savesrc" width="60%" @mouseenter="entersave" @mouseleave="leavesave" />
				<span class="bc" style="color:#FFFFFF;font-size: 0.9375rem;">保存</span>
			</div>
			<!-- <div @click="printOrder" style="display: flex;flex-direction: column;align-items: center;margin-left: 0.925rem;cursor: pointer;" >
				<img id="addfile" :src="printsrc" width="60%" @mouseenter="enterprint" @mouseleave="leaveprint" />
				<span style="color:#af9f73;font-size: 0.9375rem;">打印</span>
			</div> -->
		</div>
		<div class="fun2 sub-menue2">
			<div @click="back" style="display: flex;flex-direction: column;align-items: center;border-right: 1px solid #af9f73;cursor: pointer;padding-right: 0.625rem;" >
				<!-- <img src="../../assets/hximg/arrow-left.png" width="70%" /> -->
				<img src="../../assets/ysmimg/arrow-left.png" style="width: 29px; height: 29px;" />
				<span style="color:#af9f73 ;">返回</span>
			</div>
			
			<div @click="printOrder" style="display: flex;flex-direction: column;align-items: center;margin-left: 0.925rem;cursor: pointer;" >
				<img id="addfile" :src="printsrc" width="60%" @mouseenter="enterprint" @mouseleave="leaveprint" />
				<span id="dy" style="color:#FFFFFF;font-size: 0.9375rem;">打印</span>
			</div>
		</div>
		<div class="fun3 sub-menue3">
			<div @click="back" style="display: flex;flex-direction: column;align-items: center;border-right: 1px solid #af9f73;cursor: pointer;padding-right: 0.625rem;" >
				<!-- <img src="../../assets/hximg/arrow-left.png" width="70%" /> -->
				<img src="../../assets/ysmimg/arrow-left.png" style="width: 29px; height: 29px;" />
				<span style="color:#af9f73 ;">返回</span>
			</div>
		</div>
	</div>
	
	
</template>

<script>
	export default{
		
		data:function(){
			return{
				vis:'hidden',
				src:require('../../assets/ysmimg/addfile1.png'),
				savesrc:require('../../assets/ysmimg/save1.png'),
				printsrc:require('../../assets/ysmimg/print1.png'),
				backIndex:1,
				dname:''
			}
		},
		methods:{
			save1(){
				this.$alert("保存订单成功","提示");
			},
			goOrder(){
				console.log(document.getElementsByClassName("fun")[0]);
				document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue visible")
				document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
				this.$router.push("/ysmmain/ysmlatestorder");
			},
			settings(){
					this.$router.push("/ysmmain/ysmsettings");
			},
			//退出
			logout(){
				this.$confirm('确定要退出吗?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
				       sessionStorage.removeItem("loginInfo");
				       sessionStorage.removeItem("oid");
				       this.$router.push("/login");
				        }).catch(() => {
				          this.$alert("您取消了退出操作","提示");         
				        });
				
			},
			//去首页
			goMain(){
				this.$router.push("/selecttype");
			},
			//鼠标悬浮到添加订单的时候
			change(){
				this.src=require('../../assets/ysmimg/addfile2.png');
				document.getElementsByClassName("tjdd")[0].style.color="#af9f73"
			},
			//鼠标悬浮到添加订单的时候
			leavechange(){
				this.src=require('../../assets/ysmimg/addfile1.png');
				document.getElementsByClassName("tjdd")[0].style.color="#FFFFFF";
			},
			entersave(){
				this.savesrc=require('../../assets/ysmimg/save2.png');
				document.getElementsByClassName("bc")[0].style.color="#af9f73";
			},
			leavesave(){
				this.savesrc=require('../../assets/ysmimg/save1.png');
					document.getElementsByClassName("bc")[0].style.color="#FFFFFF";
			},
			enterprint(){
				this.printsrc=require('../../assets/ysmimg/print2.png');
				document.getElementById("dy").style.color="#af9f73";
				//document.getElementById("dy").style.fontSize="0.9375rem;";
			},
			leaveprint(){
				this.printsrc=require('../../assets/ysmimg/print1.png');
					document.getElementById("dy").style.color="#FFFFFF";
			},
			//返回
			back(){
				if(this.backIndex==2){
					document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
					document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue visible")
				}
				// this.$store.commit("setYsmStepIndex",1);
				let pa=this.$store.state.backLocation;
				console.log(pa);
				if(pa=="/ysmmain/ysmlatestorder"){
					document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
					document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
					document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2")
					document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")
				 pa="/ysmmain/ysmlatestorder";
				}else if(pa=="/ysmmain/ysmorder/ysmorderone"){
					this.$store.commit("setYsmStepIndex",1);
					
				}else if(pa=="/ysmmain/ysmorder/ysmordertwo"){
					this.$store.commit("setYsmStepIndex",2);
					console.log(9999);
					// pa="/ysmmain/ysmorder/ysmorderone";
				}else if(pa=="/ysmmain/ysmorder/ysmorderthree"){
					this.$store.commit("setYsmStepIndex",3);
				}else if(pa=="/ysmmain/ysmorder/ysmorderfour"){
					this.$store.commit("setYsmStepIndex",4);
				}else if(pa=="/ysmmain/ysmorder/ysmorderfive"){
					this.$store.commit("setYsmStepIndex",5);
				}else if(pa=="/ysmmain/ysmorder/ysmordersix"){
					this.$store.commit("setYsmStepIndex",6);
				}else if(pa=="/ysmmain/ysmorder/ysmorderseven"){
					this.$store.commit("setYsmStepIndex",7);
				}else if(pa=="/ysmmain/ysmorderyulan"){
					pa="/ysmmain/ysmlatestorder";
				}
				
				this.$router.push(pa);
			},
			//单击添加订单
			goAddOrder(){
				sessionStorage.removeItem("oid");
				this.backIndex=2;
				document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
				document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2")
				document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")
				this.$store.commit("setBackLocation","/ysmmain/ysmlatestorder");
				this.$router.push("/ysmmain/ysmorder/ysmorderone");
			},
			//打印
			printOrder(){
				var oid=sessionStorage.getItem("yloid");
				window.open("http://120.26.66.95:8089/client/order/downloadOrder?oid="+oid);
			},
			//保存订单
			SaveOrder(){
				this.$alert("保存订单成功","提示");
			},
			goSettings(){
				this.$router.push("/ysmmain/ysmsettings");
			}
		},
		mounted:function(){
			if(this.$route.path=="/hxmain/hxlatestorder"){
				document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
				document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue");
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
				document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")
			}
			console.log(this.$route.path);
			if(this.$route.path=="/ysmmain/ysmsettings"){
				document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
				document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue visible")
				document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")
			}
			if(this.$route.path=="/ysmmain/ysmorderyulan"){
				
				document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2 visible");
				document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue ")
				document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")
			}
			let doctorInfo=sessionStorage.getItem("loginInfo");
			let doctorJson=JSON.parse(doctorInfo);
			this.dname=doctorJson.dname;
			//将当前路径存储到backLocation
			this.$store.commit("setBackLocation","/ysmmain/ysmlatestorder");
		}
	}
</script>

<style scoped="scoped">
	.yh{
		width: 100%;
		position: fixed;
		top: 0;
		z-index: 10002;
		background-color: #FDFDFD;
		
	}
	.ysm-head{
		height: 6.5125rem;
		line-height: 6.5125rem;
		background-color: white;
		position: fixed;
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	.nav1{
		top:6.5125rem;
		width: 100%;
		height: 2.875rem;
		line-height: 2.875rem;
		background-color: rgba(68,68,68,.9);	
		padding-left: 0rem;
		display: flex;
		justify-content: flex-start;
		position: fixed;
		z-index: 10002;
	}
	.nav1 a{
		color: white;
		font-weight: bolder;
		margin-right: 2.8rem;
		cursor: pointer;
	}
	/* 退出按钮 */
	.logout{
		border: 1px solid red;
		min-width: 6.25rem;
		height: 2.125rem !important;
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		padding: 6px 6px !important;
		text-align: center;
		display: inline-block;
		cursor: pointer;
	}
	/* 子菜单 */
	.fun{
		background-color: rgba(68,68,68,.9);
		position: fixed;
		width: 100%;
		z-index: 10002;
		top: 9.2rem;
	}
	.fun1{
		background-color: rgba(68,68,68,.9);
		position: fixed;
		width: 100%;
		z-index: 10002;
		top: 9.2rem;
	}
	.fun2{
			background-color:rgba(68,68,68,.8);
			position: fixed;
			width: 100%;
			z-index: 10002;
			top: 9.2rem;
	}
	.fun3{
			background-color:rgba(68,68,68,.8);
			position: fixed;
			width: 100%;
			z-index: 10002;
			top: 9.2rem;
	}
	.sub-menue{
		height: 6rem;
		visibility: hidden;
		opacity: 0;
		transition: visibility .3s linear,opacity .3s linear;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 1.5rem;
	}
	.sub-menue1{
		height: 6rem;
		visibility: hidden;
		opacity: 0;
		transition: visibility .3s linear,opacity .3s linear;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 1.5rem;
	}
	.sub-menue2{
		height: 6rem;
		visibility: hidden;
		opacity: 0;
		transition: visibility .3s linear,opacity .3s linear;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 1.5rem;
	}
	.sub-menue3{
		height: 6rem;
		visibility: hidden;
		opacity: 0;
		transition: visibility .3s linear,opacity .3s linear;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 1.5rem;
	}
	.visible{
		visibility:visible;
		opacity: 1;
	}
</style>
