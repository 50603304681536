<template>
	<div class="ysmmain">
		<YsmHead></YsmHead>
		<router-view></router-view>
	</div>
</template>

<script>
	import YsmHead from "../../components/youshimei/ysm-head.vue";
	export default{
		mounted:function(){
			
			this.$router.push("/ysmmain/ysmlatestorder");
		},
		components:{
			YsmHead
			
		}
	}
</script>

<style scoped="scoped">
	.ysmmain{
		width: 100%;
		height: 100%;
		background-image: url(../../assets/ysmimg/beijing.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
	}
	footer{
		
	}
</style>
